import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Rule } from '@models/pro.model';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { PagingService } from '@services/paging.service';
import { ResultsService } from '@services/results.service';
import { TimelineService } from '@services/timeline/timeline.service';
import { NavigationService } from '@services/navigation.service';
import { ProService } from '@services/pro.service';
import { TreatmentService } from '@services/treatment.service';
import { ProductsService } from '@services/timeline/products.service';
import { LoaderService } from '@services/loader.service';
import { ProRouterService } from '@services/pro-router.service';
import { ConditionService, ConditionStateResult } from '@services/condition.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form?.submitted;
    return !!(control?.invalid && (control?.dirty || control?.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-treatment-checkup',
  templateUrl: './treatment-checkup.component.html',
  styleUrls: ['./treatment-checkup.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TreatmentCheckupComponent extends PropertiesBaseComponent implements OnChanges {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public pagingService: PagingService,
    public timelineService: TimelineService,
    private navigationService: NavigationService,
    private proService: ProService,
    private treatmentService: TreatmentService,
    private productsService: ProductsService,
    private proRouterService: ProRouterService,
    private loaderService: LoaderService,
    private conditionService: ConditionService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);

    this.debugMode = this.route.snapshot.queryParams.debug;

    this.subscriptions.add(
      this.onLoad.subscribe(async (): Promise<void> => {
        await this.handleConditionsLoad();

        Object.values(this.productsService.getProducts()).forEach((product: any) => {
          this.results[product.name] = product;
        });

        try {
          const lastOrderProducts: string[] = await this.proRouterService.customerLastOrder();

          if (lastOrderProducts && Array.isArray(lastOrderProducts)) {
            for (const products of lastOrderProducts) {
              this.oldValues[products] = products;
            }
          }

          // add current selection
        } catch (error) {
          console.error(error);
        }

        if (!this.debugMode) {
          await this.moveOn();
          this.loaderService.isReady();
        } else {
          this.loaderService.isReady();
          if (this._rules?.length) {
            this._rules.forEach(async rule => {
              (rule as any).valid = await this.dryRun(rule);
            });
          } else {
            this.dryRunKeepPlan();
          }
        }
      }),
    );

    this.subscriptions.add(
      this.route.params.subscribe(async params => {
        this.activeStepIndex = params.step_id;
      }),
    );
  }

  oldValues: any = {};

  factorsList: any;

  factorSet: any;

  activeStepIndex = 0;

  public results: Record<string, any> = {};

  debugMode = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public ngOnChanges(changes: SimpleChanges): void {}

  factors: any[] = [];

  objectToArray(obj?: Record<string, string>) {
    const arr: any[] = [];
    if (obj) {
      Object.keys(obj).forEach(key => {
        arr.push({ key, value: obj[key] });
      });
    }
    return arr;
  }

  objectToValues(obj?: Record<string, string>) {
    const arr: any[] = [];
    if (obj) {
      Object.values(obj).forEach(value => {
        arr.push(value);
      });
    }
    return arr;
  }

  userAssignedProducts: any = {};

  loadedConditions: Record<string, any> = {};
  async handleConditionsLoad() {
    console.info('handleConditionsLoad');

    const conditionsDic: any = {};
    this.proService.activePro?.steps[this.activeStepIndex]?.conditions?.forEach(condition => {
      conditionsDic[condition] = condition;
    });

    if (Object.values(conditionsDic).length) {
      const proResolution: Record<string, ConditionStateResult> = await this.proRouterService.resolveProConditions(conditionsDic);

      this.loadedConditions = proResolution;

      //  this.conditionService.setConditions(proResolution);
    }
    // }
  }

  async processCompareRules(rules: any) {
    if (!this.results) {
      return false;
    }

    Object.values(this.results).forEach((p: any) => {
      this.userAssignedProducts[p.name] = p;
    });

    let foundRule: Rule | undefined;
    for (const rule of rules) {
      const matchPair = { initial: false, target: false };
      if (this.userAssignedProducts[rule.target.name]) {
        matchPair.target = true;
      }

      if (!Object.keys(this.oldValues)?.length) {
        if (!rule.initial) {
          matchPair.initial = true;
        }
      }

      if (!this.oldValues?.length) {
        Object.values(this.results).forEach((p: any) => {
          this.oldValues[p.name] = p;
        });
      }

      const initialProduct = Object.keys(this.oldValues).find(key => {
        if (!rule.initial?.name) {
          return true;
        }
        const cleanInitial = rule.initial?.name?.toLowerCase().replace('protocol', '').trim();
        const cleanKey = key.toLowerCase().replace('protocol', '').replace('curqd®', '').trim();
        return cleanInitial?.includes(cleanKey) || cleanKey.includes(cleanInitial);
      });

      if (initialProduct) {
        matchPair.initial = true;
      }

      console.info('initial product and match', matchPair, initialProduct);
      if (matchPair.initial && matchPair.target) {
        foundRule = rule;
        break;
      }
    }

    return foundRule;
  }

  async actualizeTreatmentChange(foundRule: any) {
    if (foundRule) {
      this.activeRule = foundRule;
      const overrideTreatment = foundRule.override._id;
      if (foundRule.override.type === 'treatment') {
        const treatment = await this.treatmentService.get(overrideTreatment);
        const products = treatment.properties.products.map((p: any) => p.value);
        this.resultsService.resetProducts();
        console.info('found rule', products);
        return this.resultsService.addProducts(products);
      } else {
        const product = this.productsService.getProduct(foundRule.override._id);
        this.productsService.reset();
        console.info('adding product', product);
        return this.resultsService.addProducts([product]);
      }
    }
    return false;
  }

  activeRule: Rule | undefined;

  async doWeHaveActiveCondition() {
    await this.handleConditionsLoad();
    if (this.loadedConditions && Object.keys(this.loadedConditions)?.length) {
      for (const condition of Object.values(this.loadedConditions)) {
        if (condition.state) {
          console.info('condtion active', condition);
          return true;
        }
      }
    } else {
      return false;
    }
    return false;
  }

  async moveOn() {
    if (await this.doWeHaveActiveCondition()) {
      const rule = await this.processCompareRules(this._rules);
      await this.actualizeTreatmentChange(rule);
    }

    this.onUpdate.next({
      questionId: this.question?._id,
      result: this.activeRule,
    });

    this.resultsService.nextPage();

    // this.navigationService.movePage(true, true);
  }

  dryRunResult: any = false;

  async dryRunKeepPlan() {
    await this.processCompareRules([]);
  }

  async dryRun(rule?: Rule) {
    if (rule) {
      rule.validationResult = await this.processCompareRules([rule]);
    } else {
      this.dryRunResult = (await this.processCompareRules(this._rules)) || false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public updateProps(event: any) {}
}
